body, html {
    height: 100%;
    margin: 0;
}

.App {
    height: 100%;
    width: 100%;
    background-color: var(--background-box-bg);
    position: fixed;
}

.backgroundBox {
    border: 1px solid var(--background-box-border);
    background-color: inherit;
    position: fixed;
    left: var(--pad);
    right: var(--pad);
    top: var(--pad);
    bottom: var(--pad);
    padding: var(--pad);
}

.text-link:link, .text-link:visited {
    color: var(--text-color);
    font-size: 28px;
    letter-spacing: 3px;
    text-decoration: none;
}

.text-link:hover {
    color: var(--text-color-hover);
    text-decoration: none;
}

.profile-text {
    font-size: 40px;
    margin-bottom: 80px;
}

.home-content-text{
    font-size: 24px;
}

.side-bar {
    margin-top: 10px;
}

.content{
    display: flex;
    position: absolute;
    bottom: var(--pad);
    right: var(--pad);
    padding: var(--pad);
    flex-wrap: pre-wrap;
    word-wrap: normal;
    width: 15% auto;
    white-space: nowrap;
    margin: 0;
    font-size: calc(100% + 1vw);
}

.contact-logo {
    display: inline-flex;
    padding: 10px;
    width: 25px;
    height: 25px;
}

.contact-link:link, .contact-link:visited {
    display: flex;
    text-align: center;
    color: var(--text-color);
    text-decoration: none;
    font-size: 24px;
}

.contact-link:hover {
    color: var(--text-color-hover)
}

.email {
    font-size: 24px
}

.info-content {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: var(--pad);
    flex-wrap: pre-wrap;
    word-wrap: normal;
    width: 30%;
    height: 80%;
    overflow-y: scroll;
    letter-spacing: 1px;
}

.info-logo {
    display: flex;
    width: 50%;
    margin-left: .5vw;
    margin-bottom: .5vw;
}

.info-work-point {
    font-size: calc(100% + .2vw);
}

.project-content {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: var(--pad);
    flex-wrap: pre-wrap;
    word-wrap: normal;
    height: 60%;
    width: 40%;
    overflow-y: scroll;
}

/* .project-group {

} */

.project-title:link, .project-title:visited {
    display: block;
    font-size: calc(100% + 1.1vw);
    color: var(--text-color);
    font-weight: bold;
    text-align: right;
    text-decoration: none;
}

.project-title:hover {
    color: var(--text-color-hover)
}

.project-desc {
    display: block;
    font-size: calc(100% + .2vw);
    text-align: right;
}

.theme-button {
    position: fixed;
    z-index: 10;
    left: calc(var(--pad) - 0.15em);
    bottom: var(--pad);
    transform-origin: left bottom;
    transform: rotate(-90deg);
    white-space: nowrap;
    display: flex;
    column-gap: 15px;
    cursor: pointer;
}

.theme-container {
    display: inline-flex;
    align-items: center;
    column-gap: 3px;
}

.theme-box {
    width: auto;
    height: auto;
}
.theme-text {
    letter-spacing: 2px;
    font-size: 20px;
}

span {
    display: block;
    font-size: inherit;
}

b{
    display: block;
    white-space: 'pre-wrap';
    font-size: calc(100% + 1vw);
}

ul {
    padding-left: 1vw;
}

::-webkit-scrollbar {
    width: 10px;
}

a:any-link {
    cursor: pointer;
}

.wave {
    animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s;        /* Change to speed up or slow down */
    animation-iteration-count: infinite;  /* Never stop waving :) */
    transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
    display: inline-block;
  }
  
  @keyframes wave-animation {
      0% { transform: rotate( 0.0deg) }
     10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
     20% { transform: rotate(-8.0deg) }
     30% { transform: rotate(14.0deg) }
     40% { transform: rotate(-4.0deg) }
     50% { transform: rotate(10.0deg) }
     60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
    100% { transform: rotate( 0.0deg) }
  }

:root {
    --pad : max(20px, 4vmin);
}

.light {
    --background-box-border: rgba(0, 0, 0, 0.9);
    --background-box-bg: rgba(0, 0, 0, 0.1);
    --text-color: rgba(0, 0, 0, 0.9);
    --text-color-hover: rgba(0, 0, 0, 0.5);
}

.dark {
    --background-box-border: #f5f6f7;
    --background-box-bg: #121212;
    --text-color: #d6d1d1;
    --text-color-hover: #999;
}
* {
    font-family: 'Bebas Neue';
    color: var(--text-color);
    text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    overscroll-behavior: none;
}
@font-face {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/BebasNeue-Regular.ttf');
}
